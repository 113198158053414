import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { AnyAction, combineReducers } from "redux";
import { PersistState } from "redux-persist/es/types";

import { userReducer, UserReducerState } from "../reducers/UserReducer";
import { documentReducer, DocumentReducerState } from "../reducers/DocumentReducer";
import { appReducer, appReducerPersistConfig, AppReducerState } from "../reducers/AppReducer";
import { authReducer, authReducerPersistConfig, AuthReducerState } from "../reducers/AuthReducer";

interface PersistPartial {
  readonly _persist: PersistState;
}

export interface AppStoreState {
  readonly user: UserReducerState;
  readonly document: DocumentReducerState;
  readonly app: AppReducerState & PersistPartial;
  readonly auth: AuthReducerState & PersistPartial;
}

export const rootReducer = combineReducers<AppStoreState>({
  user: userReducer,
  document: documentReducer,
  app: persistReducer<AppReducerState, AnyAction>(
    {
      ...appReducerPersistConfig,
      key: "app",
      storage,
    },
    appReducer,
  ),
  auth: persistReducer<AuthReducerState, AnyAction>(
    {
      ...authReducerPersistConfig,
      key: "auth",
      storage,
    },
    authReducer,
  ),
});
